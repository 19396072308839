<template>
  <v-container fluid class="white-background width-95">
    <transition name="fade-in-up">
      <router-view></router-view>
    </transition>
  </v-container>
</template>

<script>
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
export default {
  components: {},
  updated() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
  },
};
</script>
